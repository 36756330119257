<template>
  <v-card
    max-height="calc(100vh - 48px - 32px)"
    class="rounded-lg"
    >
    <v-card-text
      class="black--text"
      >
      <div
        class="text-center"
        >
        <v-icon
          x-large
          color="primary"
          >
          mdi-handshake-outline
        </v-icon>
      </div>

      <div
        class="text-h6 font-weight-bold text-center mb-3 primary--text"
        >
        Sponsors
      </div>
      
      <div
        class="d-flex flex-wrap justify-center"
        style="gap: 8px"
        >
        <template
          v-for="sponsor in sponsors"
          >
          <sponsor
            :sponsor="sponsor"
            @reload="reload"
            ></sponsor>
        </template>

        <create
          @reload="reload"
          ></create>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { Sponsors } from '@/graphql/queries/fairs/sponsors'

import Create from './sponsors/Create'
import Sponsor from './sponsors/Sponsor'

export default {
  data: () => ({
    sponsors: []
  }),

  props: {
    fair: {
      type: Object,
      required: true
    }
  },

  created () {
    this.fetchSponsors()
  },

  methods: {
    fetchSponsors () {
      this.$apollo.query({
        query: Sponsors,
        variables: {
          id: this.fair.id
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.sponsors = res.data.sponsors
      })
    },

    reload () {
      this.fetchSponsors()
    }
  },

  components: {
    Create,
    Sponsor
  }
}
</script>
