import gql from 'graphql-tag'

export const Sponsors = gql`
  query sponsors($id: ID!) {
    sponsors(id: $id) {
      id
      name
      image {
        id
        url
        thumbUrl
      }
    }
  }
`
