<template>
  <v-dialog
    v-model="dialog"
    width="600"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        color="error"
        v-on="on"
        v-bind="attrs"
        >
        <v-icon>
          mdi-delete
        </v-icon>
      </v-btn>
    </template>

    <v-card
      class="rounded-lg"
      >
      <v-card-title>
        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="dialog = false"
          >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        class="px-6 pb-6"
        >
        <div
          class="text-center text-h5 text--primary font-weight-medium"
          >
          ELIMINAR SPONSOR
        </div>

        <div
          >
          <div
            class="text-h6 black--text my-3 text-center font-weight-regular"
            >
            ¿Está seguro de que desea eliminar el sponsor <span class="black--text font-weight-medium">{{ sponsor.name }}</span>?
          </div>

          <v-btn
            block
            color="error"
            depressed
            block
            class="rounded-lg"
            @click="deleteSponsor"
            >
            Eliminar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { DeleteSponsor } from '@/graphql/mutations/fairs/sponsors'

export default {
  data: () => ({
    dialog: false,
  }),

  props: {
    sponsor: {
      type: Object,
      required: true
    }
  },

  methods: {
    deleteSponsor () {
      this.$apollo.mutate({
        mutation: DeleteSponsor,
        variables: {
          input: {
            id: this.sponsor.id
          }
        }
      }).then ( res => {
        this.dialog = false
        this.$emit('reload')
      })
    }
  }
}
</script>
