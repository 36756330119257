<template>
  <v-dialog
    v-model="dialog"
    width="600"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-card
        outlined
        class="rounded-lg"
        width="100%"
        v-on="on"
        v-bind="attrs"
        >
        <v-card-text
          class="primary--text font-weight-medium d-flex flex-row align-center fill-height"
          style="gap: 12px"
          >
          <div
            class="text-center"
            style="width: 80px"
            >
            <v-icon large color="primary">mdi-plus-circle</v-icon>
          </div>

          <div
            class="body-1 text-center primary--text"
            >
            Nuevo sponsor
          </div>
        </v-card-text>
      </v-card>
    </template>

    <v-card
      class="rounded-lg"
      >
      <v-card-title>
        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="dialog = false"
          >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        class="px-6 pb-6"
        >
        <div
          class="text-center text-h5 text--primary font-weight-medium"
          >
          CREAR SPONSOR
        </div>

        <div
          class="my-3 mx-auto primary"
          style="height: 4px; width: 100px;"
          ></div>

        <div
          >
          <v-form
            v-model="valid"
            ref="form"
            lazy-validation
            class="d-flex flex-column align-center"
            style="gap: 4px"
            >
            <div
              style="position: relative;"
              >
              <v-avatar
                v-if="imageUrl"
                color="primary"
                size="130"
                >
                <v-img
                  :src="imageUrl"
                  ></v-img>
              </v-avatar>

              <v-avatar
                v-else
                color="secondary"
                size="130"
                >
                <v-icon
                  color="white"
                  size="60"
                  >
                  mdi-account
                </v-icon>
              </v-avatar>

              <v-btn
                fab
                x-small
                style="position: absolute; right: calc(50% - 16px); bottom: -16px; z-index: 2"
                @click="openChangePicture"
                >
                <v-icon
                  color="primary"
                  >
                  mdi-camera
                </v-icon>
              </v-btn>

              <input
                class="d-none"
                ref="picture"
                type="file"
                accept="image/*"
                @change="uploadPicture"
                ></input>
            </div>

            <v-text-field
              v-model="attributes.name"
              outlined
              class="rounded-lg mt-6"
              :rules="required"
              label="Nombre del sponsor"
              dense
              ></v-text-field>

            <v-btn
              block
              color="primary"
              depressed
              block
              class="rounded-lg"
              @click="createSponsor"
              >
              Confirmar
            </v-btn>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { CreateSponsor } from '@/graphql/mutations/fairs/sponsors'

export default {
  data: () => ({
    dialog: false,
    valid: true,
    imageUrl: null,
    startsAtMenu: false,
    endsAtMenu: false,
    required: [
      v => !!v || 'Campo obligatorio'
    ],
    attributes: {
      name: null,
      imageAttributes: {
        name: null,
        size: null,
        image: null,
      }
    }
  }),

  methods: {
    openChangePicture () {
      this.$refs.picture.click()
    },

    uploadPicture (e) {
      var file = e.target.files[0]

      this.imageUrl = URL.createObjectURL(file);

      this.attributes.imageAttributes ={
        name: file.name,
        size: file.size,
        image: file,
      }
    },

    createSponsor () {
      if (this.$refs.form.validate()) {
        this.$apollo.mutate({
          mutation: CreateSponsor,
          variables: {
            input: {
              attributes: this.attributes,
              id: this.$route.params.id
            }
          }
        }).then ( res => {
          this.$emit('reload')

          this.$refs.form.reset()
          this.dialog = false
        })
      }
    }
  }
}
</script>
