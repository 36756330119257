import gql from 'graphql-tag'

export const CreateSponsor = gql`
  mutation createSponsor($input: CreateSponsorInput!) {
    createSponsor(input: $input) {
      success
    }
  }
`

export const EditSponsor = gql`
  mutation editSponsor($input: EditSponsorInput!) {
    editSponsor(input: $input) {
      success
    }
  }
`

export const DeleteSponsor = gql`
  mutation deleteSponsor($input: DeleteSponsorInput!) {
    deleteSponsor(input: $input) {
      success
    }
  }
`
