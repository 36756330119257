<template>
  <v-card
    outlined
    class="rounded-lg"
    width="100%"
    >
    <v-card-text
      class="d-flex flex-row align-center"
      style="gap: 12px; position: relative"
      >
      <v-avatar
        color="primary"
        size="80"
        >
        <v-img
          v-if="sponsor.image && sponsor.image.url"
          :src="url + sponsor.image.url"
          ></v-img>
      </v-avatar>

      <div
        class="primary--text body-1 font-weight-medium store-card-box"
        >
        {{ sponsor.name }}
      </div>

      <v-spacer></v-spacer>

      <div
        class="text-center"
        >
        <delete
          @reload="$emit('reload')"
          :sponsor="sponsor"
          ></delete>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

import Delete from './Delete'

export default {
  props: {
    sponsor: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters(['url'])
  },

  components: {
    Delete,
  }
}
</script>
